import { useEffect, useState } from "react";
import Modal from "react-modal/lib/components/Modal";
import closeIcone from "../../../assets/close.png";
import "./style.css";
import api_horse from "../../../services/api_horse";
import {NumericFormat} from "react-number-format";
import InputMask from 'react-input-mask';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';


function ContatoModal(props){

    const [idcontato, setIdContato] = useState(props.id);
    const [telefone, setTelefone] =useState("");
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [medico, setMedico] = useState("");
    const [cpf, setCPF] = useState("");
    const [observacao, setObservacao] = useState("");
    const [tipoavaliacao, setVTipoAvaliacao] =useState("");
    const [nivel_interesse, setNivel_Interesse] = useState("");
    const [tecnica, setTecnica] = useState("");
    const [dor, setDor] = useState("");
    const [valor_venda, setValor_Venda] = useState(0);
    const [desconto_por, setDesconto_por] = useState("");
    const [empresa, setEmpresa ]= useState(localStorage.getItem('id_empresa'));

    const [isInternacional, setIsInternacional] = useState(false);
    const [errorTelefone, setErrorTelefone] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [mensagem, setMensagem] = useState("");
    const [errorCpf, setErrorCpf] = useState('');
  

    const opcoesAvaliado = [
        { id: 1, nome: '' },
        { id: 2, nome: 'AVALIADO THAIS' },
        { id: 3, nome: 'AVALIADO QUEMELA' },
        { id: 4, nome: 'AVALIADO PALOMA' },
        { id: 5, nome: 'AVALIADO JOÃO' },
        { id: 6, nome: 'AVALIADO LUANA' },
        { id: 7, nome: 'AVALIADO ANA PAULA' },
        { id: 8, nome: 'AVALIADO LEONORA' },
        { id: 9, nome: 'AVALIADO FERNANDA' }
      ];

      const handleMedico = (event) => {
        setMedico(event.target.value);
      };


    function SalvarContato(){
      setMensagem("");
      //const cpfSemMascara = cpf.replace(/\D/g, ''); // Remove tudo que não é número do CPF
      //const telefoneSemMascara = telefone.replace(/\D/g, ''); // Remove tudo que não é número do telefone

      if (idcontato > 0){
          api_horse.put(`/v1/contato/${idcontato}`,{
              telefone,
              nome,
              cpf,
              email,
              empresa,
              medico,
              observacao,
              tipoavaliacao,
              observacao,
              tipoavaliacao,
              nivel_interesse,
              tecnica,
              dor,
              valor_venda,
              desconto_por
          })
          .then(retorno => {          
              if (retorno.response){
                  if (retorno.response.status != "200"){
                      //alert(retorno.response.data.erro);
                      setMensagem(retorno.response.data.erro);
                  }
              } else {
                 setMensagem("Salvo com sucesso!"); // Exibe mensagem de sucesso
                 setTimeout(() => {
                    props.onRequestClose(); // Fecha o modal
                }, 3000); // 3000ms = 3s
                          
              }
          })
          .catch(err => {        
              console.log(err) ;
              if (err.response) {
                  setMensagem(err.response.data.erro);
              } else {
                  setMensagem('Ocorreu um erro na requisição.');
              }
          });
      } else {
        api_horse.post(`/v1/contato/${idcontato}`, {
          telefone,
          nome,
          cpf,
          email,
          empresa,
          medico,
          tipoavaliacao,
          observacao,
          tipoavaliacao,
          nivel_interesse,
          tecnica,
          dor,
          valor_venda,
          desconto_por                          
          })
          .then(retorno => {  
              if (retorno.response){
                  if (retorno.response.status != "200"){
                      setMensagem(retorno.response.data.erro);
                  }
              } else {          
                setTimeout(() => {
                    props.onRequestClose(); // Fecha o modal
                }, 3000); // 3000ms = 3s
                  //props.onClickSave();
              }
          })
          .catch(err => {            
              if (err.response) {
                  setMensagem(err.response.data.erro);
              } else {
                  setMensagem('Ocorreu um erro na requisição.');
              }
          });
      }
  }

  function ExportarContato(){
    setMensagem("");
    console.log("idcontato : " + idcontato)
    
    const errorsArray = [];
    // Apenas validar o CPF ao exportar

    if (!cpf) {
        setMensagem("CPF não pode ser vazio para exportação.");
        return; // Interrompe a função se o CPF estiver vazio
    }


    if (idcontato > 0){
      api_horse.post(`/v1/contato/exportar/${idcontato}`, {
        telefone,
        nome,
        cpf,
        email,
        empresa,
        medico,

        tipoavaliacao,
        observacao,
        tipoavaliacao,
        nivel_interesse,
        tecnica,
        dor,
        valor_venda,
        desconto_por                          
        })
        .then(retorno => {  
            if (retorno.response){
                if (retorno.response.status != "200"){
                    setMensagem(retorno.response.data.erro);
                }
            } else {          
                setTimeout(() => {
                    props.onRequestClose(); // Fecha o modal
                }, 3000); // 3000ms = 3s
                //props.onClickSave();
            }
        })
        .catch(err => {            
            if (err.response) {
                setMensagem(err.response.data.erro);
            } else {
                setMensagem('Ocorreu um erro na requisição.');
            }
        });
    }
}


  useEffect(() => {
    setIdContato(props.dados_contato.id);
    setTelefone(props.dados_contato.telefone);
    setNome(props.dados_contato.apelido);
    setCPF(props.dados_contato.cpf);
    setMedico(props.dados_contato.medico);
    setDesconto_por(props.dados_contato.desconto_por);
    setDor(props.dados_contato.dor);
    setEmail(props.dados_contato.email);
    setEmpresa(localStorage.getItem('id_empresa'));
    setNivel_Interesse(props.dados_contato.nivel_interesse);
    setMensagem("");
}, [props.isOpen])

 const [error, setError] = useState('');

  // Função para lidar com mudanças no CPF
  const handleCpfChange = (e) => {
    setCPF(e.target.value);
 };

  // Função para validar o CPF quando o campo perde o foco (onBlur)
// Validação de CPF (pode manter como está)
const validateCPF = () => {
    if (!cpfValidator.isValid(cpf)) {
      setErrorCpf('CPF inválido');
    } else {
      setErrorCpf('');
    }
  };

  // Validação de telefone (opcional, só para garantir um valor mínimo)
  const validateTelefone = () => {
    const telefoneNumerico = telefone.replace(/\D/g, ''); // Remove tudo que não for número
    if (telefoneNumerico.length < 11) {
      setErrorTelefone('Número de telefone inválido');
    } else {
      setErrorTelefone('');
    }
  };

  const handleTelefoneChange = (e) => {
    setTelefone(e.target.value);
  };

  // Função para alternar entre número internacional e local
  const handleInternacionalChange = (e) => {
    setIsInternacional(e.target.checked);
    setTelefone(''); // Limpa o campo ao alternar entre internacional/local
  };


  const validateEmail = () => {
    // Expressão regular para validar o formato do email
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(email)) {
      setErrorEmail('Email inválido');
    } else {
      setErrorEmail('');
    }
  };


    return <Modal isOpen={props.isOpen}
                  onRequestClose={props.onRequestClose}
                  overlayClassName="react-modal-overlay"
                  className="react-modal-content">

                <button type="button" onClick={props.onRequestClose} className="react-modal-close">
                    <img src={closeIcone} alt="Fechar" />
                </button>

                <h4>Contatos</h4>

                <div className="row">
  {/* Primeira Coluna */}
  <div className="col-md-6 mb-2">

  <label htmlFor="floatingInput">Nome Contato :</label>
    <div className="form-floating mb-2">
      <input
        type="text"
        onChange={(e) => setNome(e.target.value)}
        value={nome}
        className="form-control"
        id="nome"
        placeholder="Apelido"
      />
      
    </div>


    <label htmlFor="telefone">Telefone Celular :</label>
      <div className="form-floating mb-2">
        <InputMask
          mask="+55 (99) 99999-9999"
          value={telefone}
          onChange={handleTelefoneChange}
          onBlur={validateTelefone}
          disabled={isInternacional} // Desabilita a máscara para números internacionais
          className={`form-control ${errorTelefone ? 'is-invalid' : ''}`}
          id="telefone"
          placeholder="Digite seu telefone"
        />
        {errorTelefone && <div className="invalid-feedback">{errorTelefone}</div>}
      </div>


    <label htmlFor="floatingInput">Observação :</label>
    <div className="form-floating mb-2">
      <input
        type="text"
        onChange={(e) => setObservacao(e.target.value)}
        value={observacao}
        className="form-control"
        id="url_foto"
        placeholder="Observação"
      />
    </div>

    <label htmlFor="floatingInput">Avaliado por :</label>
    <div className="form-control form-produto mb-2">
                        <select name="medico" id="medico" onChange={handleMedico} value={medico}>                         
                            {
                                opcoesAvaliado.map(opcao => {
                                    return <option key={opcao.nome} value={opcao.nome} >{opcao.nome}</option>
                                })
                            }                                                            
                        </select>                            
        </div>	

    <label htmlFor="floatingInput">Técnica :</label>
    <div className="form-floating mb-2">
      <input
        type="text"
        onChange={(e) => setTecnica(e.target.value)}
        value={tecnica}
        className="form-control"
        id="url_foto"
        placeholder="Técnica"
      />
    </div>

    <label htmlFor="floatingInput">Valor Venda :</label>
    <div className="form-floating mb-2">
      <input
        type="text"
        onChange={(e) => setValor_Venda(e.target.value)}
        value={valor_venda}
        className="form-control"
        id="url_foto"
        placeholder="Valor Venda"
      />
    </div>

  </div>

  {/* Segunda Coluna */}
  <div className="col-md-6 mb-2">


  <label htmlFor="floatingInput">CPF :</label>
        <div className="form-floating mb-2">
          <input
            type="text"
            onChange={(e) => setCPF(e.target.value)}
            value={cpf}
            className="form-control"
            id="url_foto"
            placeholder="CPF"
          />
        </div>


      <label htmlFor="floatingInput">Email :</label>   
    <div className="form-floating mb-2">
      <input
        type="text"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        className="form-control"
        id="url_foto"
        placeholder="Email"
      />
   </div>
    
    <label htmlFor="floatingInput">Tipo Avaliação :</label>   
    <div className="form-floating mb-2">
      <input
        type="text"
        onChange={(e) => setVTipoAvaliacao(e.target.value)}
        value={tipoavaliacao}
        className="form-control"
        id="url_foto"
        placeholder="Tipo Avaliação"
      />
   </div>

   <label htmlFor="floatingInput">Nível Interesse :</label>
    <div className="form-floating mb-2">
      <input
        type="text"
        onChange={(e) => setNivel_Interesse(e.target.value)}
        value={nivel_interesse}
        className="form-control"
        id="url_foto"
        placeholder="Nivel Interesse"
      />
    </div>


    <label htmlFor="floatingInput">Dor :</label>
    <div className="form-floating mb-2">
      <input
        type="text"
        onChange={(e) => setDor(e.target.value)}
        value={dor}
        className="form-control"
        id="url_foto"
        placeholder="Dor"
      />
   
    </div>

    <label htmlFor="floatingInput">Desconto por :</label>
    <div className="form-floating mb-2">
      <input
        type="text"
        onChange={(e) => setDesconto_por(e.target.value)}
        value={desconto_por}
        className="form-control"
        id="url_foto"
        placeholder="Desconto por"
      />
    </div>
  </div>
</div>                
            {/* Mensagem de sucesso ou erro */}
            {mensagem.length > 0 && (
                        <div className={`alert ${mensagem === "Salvo com sucesso!" ? 'alert-success' : 'alert-danger'} mt-2 text-center`} role="alert">
                            {mensagem}
                        </div>
                    )}

<div className="row">
    <div className="col-12 mt-3 d-flex justify-content-end">
        <div>                                                  
            <button className="btn btn-secondary ms-4" onClick={ExportarContato}>Exportar Pré-Paciente </button>
            <button className="btn btn-danger ms-4" onClick={SalvarContato}>Salvar</button>
            <button className="btn btn-outline-danger ms-2" onClick={props.onRequestClose}>Fechar</button>
        </div>
    </div>
</div>



        </Modal>
}

export default ContatoModal;