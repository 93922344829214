import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard/dashboard.jsx";
import Negocio from "./pages/negocio/negocio.jsx"
import PrePaciente from "./pages/pacientepre/pacientepre.jsx"
import Login from "./pages/login/login.jsx";
import PrivateRoute from "./components/private-route/private-route.jsx";
import Contatos from "./pages/contato/contato.jsx";
import FormularioPreOperatorio from "./pages/anamnese/sanos/index.jsx"
import Paciente from "./pages/paciente/index.jsx";

function Rotas() {
    return <BrowserRouter>
        <Routes>
            <Route path="/anamnese" element={<FormularioPreOperatorio />} />
            <Route path="/login" element={<Login />} />

            <Route path="/dashboard" element={
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>
            } />

            <Route path="/" element={
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>
            } />

            <Route path="/negocios" element={
                <PrivateRoute>
                    <Negocio />
                </PrivateRoute>
            } />

            <Route path="/prepaciente" element={
                <PrivateRoute>
                    <PrePaciente />
                </PrivateRoute>
            } />

            <Route path="/contato" element={
                <PrivateRoute>
                    <Contatos />
                </PrivateRoute>
            } />

<Route path="/paciente" element={
                <PrivateRoute>
                    <Paciente />
                </PrivateRoute>
            } />

        </Routes>
    </BrowserRouter>
}

export default Rotas;