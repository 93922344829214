import axios from "axios";

const URL = "https://api.laserapp.com.br";

const api = axios.create({
    baseURL: URL,
    auth: {
        username: "99coders",
        password: "112233"    
    }
});

export default api;