import Busca from "../../components/busca/busca.jsx";
import Menu from "../../components/menu/menu.jsx";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import api_horse from "../../services/api_horse.js";
import { useEffect, useState } from "react";
import Navbar from "../../components/navbar/index.jsx";


function Paciente() {

}
export default Paciente;