import React , { useEffect, useState, useRef } from "react";
import api_horse from '../../services/api_horse.js';
import Navbar from "../../components/navbar/index.jsx";
import Menu from "../../components/menu/menu.jsx";
import ContatoModal from "../../components/contato/modal/index.jsx";
import "./contato.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable  , ptBR  } from "primereact/datatable" ;
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar"
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Tag } from 'primereact/tag';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';         

function Contatos(){

    const [contatos, setContatos] = useState([]);
    const [isContatoOpen, setIsContatoOpen] = useState(false);
    const [dados_contato, setDados_Contato] = useState({idcontato: 0});
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [contatoDialog, setContatoDialog] = useState(false);
    const [deleteContatoDialog, setDeleteContatoDialog] = useState(false);
    const [contato, setContato] = useState(null);
    const [selectedContatos, setSelectedContatos] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);


    const getSeverity = (status) => {
        switch (status) {
            case 'Aguardando':
                return 'danger';

            case 'Finalizado':
                return 'success';
        }
    };


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" rounded outlined className="mr-2 me-1" onClick={() => OpenModalContato(rowData.id)}   />
                <Button icon="pi pi-trash" rounded outlined className="mr-2 me-1" severity="danger" onClick={() => Excluir(rowData.id)} disabled={loading}  />
            </React.Fragment>
        );
    };

    const actionBodyTemplate2 = () => {
        return <Button type="button" icon="pi pi-cog" rounded></Button>;
    };


    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Novo" icon="pi pi-plus" severity="success" onClick={() => OpenModalContato(0)} disabled={loading} />
            </div>
        );
    };


    const formatDate = (value) => {
        return value.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };
    const DataExameBodyTemplate = (rowData) => {
        const data_exame = new Date(rowData.created_at.substring(0, 19)); 
        return ( formatDate(data_exame) );
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.nivel_interesse} severity={getSeverity(rowData.nivel_interesse)} />;
    };
    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };
    
    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yyyy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };
    function Excluir(id){
        
        confirmAlert({
            title: 'Exclusão',
            message: 'Confirma exclusão de contato?',
            buttons: [
              {
                label: 'Sim',
                onClick: () => {
                    api_horse.delete(`/v1/contato/${id}`)
                        .then(retorno => {
                            if (retorno.response){
                                if (retorno.response.status != "200"){
                                    alert(retorno.response.data.erro);
                                }
                            } else {    
                                ListarContatos();
                            }
                        })
                        .catch(err => {            
                            console.log(err);
                        }); 
                }
              },
              {
                label: 'Não',
                onClick: () => {}
              }
            ]
          });
        
    }

    function ListarContatos(){
        api_horse.post('/v1/contato/listar', {
            empresa: localStorage.getItem('id_empresa') ,
        })
        .then(response => setContatos(response.data))
        .catch(err => console.log(err));
    }

    function OpenModalContato(id){

        console.log(id)
        if (id > 0){
            api_horse.get(`/v1/contato/${id}`)
            .then(response => {                 
                setDados_Contato(response.data[0]);
                setIsContatoOpen(true);
            })
            .catch(err => {            
                console.log(err);
            });        
        } else {
            setDados_Contato({id: 0,  telefone: "", dor: "", nivel_interesse: "", nome: "" , cpf: "" , medico:"", observacao:"",
                tipoavaliacao:"", nivel_interesse:"",tecnica:"" , desconto_por :"", valor_venda: 0, email: "", apelido:""});
           setIsContatoOpen(true);
        }                   
    }

    function closeModalContato(){
        setIsContatoOpen(false);
        setLoading(false);
        ListarContatos();
    }

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            apelido: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            nivel_interesse: { value: null, matchMode: FilterMatchMode.EQUALS },
            medico: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            created_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            medico: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            dor: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            niv: { value: null, matchMode: FilterMatchMode.EQUALS }
        });
        setGlobalFilterValue('');
    };


    const renderHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
                <h5 className="m-0">Pesquisar ... </h5>
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Pesquisar ... " />
                </IconField>
            </div>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <IconField iconPosition="left">
                <InputIcon className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="pesquisando..." />
            </IconField>
        </div>
    );

    const [statuses] = useState(['DESINTERESSADO', 'INTERESSADO', 'MUITO INTERESSADO', '', '']);


    const statusRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
        );
    };
    
    useEffect(() => ListarContatos(), []);

    return <div className="container-fluid mt-page">

    <Navbar />

    <ContatoModal isOpen={isContatoOpen}    
                       dados_contato={dados_contato}
                       onRequestClose={closeModalContato}
                        />

    <div className="row flex-nowrap">
    <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 mt-50">

        <Menu page="contato" />
    </div>

    <div className="col py-3 me-3 ms-10">

       
       <div className="card">

        <div>
            <Toast ref={toast} />
            <div className="card">
            <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>
            <DataTable  ref={dt} locale="pt_BR" value={contatos} header={header} responsiveLayout="scroll" size="small" removableSort paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ Width: '100%' }}
            selectionMode="checkbox" selection={selectedContatos} onSelectionChange={(e) => setSelectedContatos(e.value)}
            filters={filters} filterDisplay="menu" globalFilterFields={['apelido', 'nivel_interesse','telefone']}
            currentPageReportTemplate="Mostrando {first} de {last} of {totalRecords} contatos" globalFilter={globalFilter} >
            
            <Column field="apelido" header="Apelido"  filter filterPlaceholder="Procurar por nome" sortable style={{ width: '15%' }} >  </Column>
            <Column field="created_at" Column header="Data " filterField="created_at" dataType="date"  style={{ width: '10%' }}  body={DataExameBodyTemplate} filter filterElement={dateFilterTemplate}   />	
            <Column field="telefone" header="Telefone"  style={{ width: '15%' }} ></Column>
            <Column field="nivel_interesse" header="Nivel_interesse" showFilterMenu={false} filterMenuStyle={{ width: '10%' }} style={{ minWidth: '8%' }} body={statusBodyTemplate} filter filterElement={statusRowFilterTemplate} />
            <Column async  body={actionBodyTemplate} exportable={false} style={{ width: '12%' }}> </Column>
            </DataTable>      
            </div>
            </div>  
            </div>
            </div>
        </div>   
        </div>        
}

export default Contatos;