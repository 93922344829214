import React, { useState, useEffect } from 'react';
import api_horse from "../../../services/api_horse.js";
import './index.css'; // Arquivo CSS separado para manter o estilo

function FormularioPreOperatorio(props) {
  const [idFormulario, setIdFormulario] = useState(0);
  const [nomeCompleto, setNomeCompleto] = useState('');
  const [cpf, setCpf] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [idadePesoAltura, setIdadePesoAltura] = useState('');
  const [alergias, setAlergias] = useState('');
  const [profissao, setProfissao] = useState('');
  const [cep, setCep] = useState('');
  const [enderecoCompleto, setEnderecoCompleto] = useState('');
  const [problemasSaude, setProblemasSaude] = useState('');
  const [remedios, setRemedios] = useState('');
  const [cirurgias, setCirurgias] = useState('');
  const [fumante, setFumante] = useState('');
  const [drogas, setDrogas] = useState('');
  const [comoConheceu, setComoConheceu] = useState('');
  const [concordo, setConcordo] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [popupVisible, setPopupVisible] = useState(false); // Controle do popup de sucesso

  const limparFormulario = () => {
    setIdFormulario(0);
    setNomeCompleto('');
    setCpf('');
    setDataNascimento('');
    setIdadePesoAltura('');
    setAlergias('');
    setProfissao('');
    setCep('');
    setEnderecoCompleto('');
    setProblemasSaude('');
    setRemedios('');
    setCirurgias('');
    setFumante('');
    setDrogas('');
    setComoConheceu('');
    setConcordo(false);
  };
  const formatarDataParaPtBr = (data) => {
    if (!data) return '';
    const [ano, mes, dia] = data.split('-');
    return `${dia}/${mes}/${ano}`;
  };

  // Função para converter a data para o formato yyyy-mm-dd para o backend
  const formatarDataParaMySQL = (data) => {
    if (!data) return '';
    const [dia, mes, ano] = data.split('/');
    return `${ano}-${mes}-${dia}`;
  };

  const salvarFormulario = () => {
    // Verificação dos campos obrigatórios
    if (!nomeCompleto || !cpf || !dataNascimento || !idadePesoAltura || !profissao || !cep || !enderecoCompleto || !concordo) {
      setMensagem('Preencha todos os campos obrigatórios e aceite os termos.');
      return;
    }

    const dataFormatadaMySQL = formatarDataParaMySQL(dataNascimento);
    const dt_pedido = new Date(dataNascimento.substring(0, 19)); // 2023-03-09T19:45:54.209Z

    console.log(dt_pedido)

    const dados = {
      nomeCompleto,
      cpf,
      dataNascimento,
      idadePesoAltura,
      alergias,
      profissao,
      cep,
      enderecoCompleto,
      problemasSaude,
      remedios,
      cirurgias,
      fumante,
      drogas,
      comoConheceu,
      concordo,
    };

    const endpoint = idFormulario > 0 ? `/v1/formularios/${idFormulario}` : '/v1/formularios';
    api_horse.post(endpoint, dados)
      .then((retorno) => {
        if (retorno.response && retorno.response.status !== 200) {
          setMensagem(retorno.response.data.erro);
        } else {
          setMensagem('Formulário salvo com sucesso!');
          setTimeout(() => {
            limparFormulario(); // Limpa o formulário
            setMensagem('');
          }, 3000); // Fecha o modal após 3 segundos
        }
      })
      .catch((err) => {
        if (err.response) {
          setMensagem(err.response.data.erro);
        } else {
          setMensagem('Ocorreu um erro ao salvar o formulário.');
        }
      });
  };

  useEffect(() => {
    setIdFormulario(props.id || 0);
    setNomeCompleto(props.nomeCompleto || '');
    setCpf(props.cpf || '');
    setDataNascimento(formatarDataParaPtBr(props.dataNascimento || ''));
    setIdadePesoAltura(props.idadePesoAltura || '');
    setAlergias(props.alergias || '');
    setProfissao(props.profissao || '');
    setCep(props.cep || '');
    setEnderecoCompleto(props.enderecoCompleto || '');
    setProblemasSaude(props.problemasSaude || '');
    setRemedios(props.remedios || '');
    setCirurgias(props.cirurgias || '');
    setFumante(props.fumante || '');
    setDrogas(props.drogas || '');
    setComoConheceu(props.comoConheceu || '');
    setConcordo(false); // Desmarca o checkbox ao abrir o modal
    setMensagem("");
  }, [props]);

  return (
    <div className="formulario-container">
      <h2>Informações Pré-Operatórias (Anamnese)</h2>
      
      <div className="formulario">
        <label>Nome Completo *</label>
        <input type="text" value={nomeCompleto} onChange={(e) => setNomeCompleto(e.target.value)} />

        <label>CPF *</label>
        <input type="text" value={cpf} onChange={(e) => setCpf(e.target.value)} />

        <label>Data de Nascimento *</label>
        <input type="date" value={dataNascimento} onChange={(e) => setDataNascimento(e.target.value)} placeholder="dd/mm/yyyy" />        

        <label>Idade | Peso | Altura *</label>
        <input type="text" value={idadePesoAltura} onChange={(e) => setIdadePesoAltura(e.target.value)} />

        <label>Tem alergia a algum medicamento?</label>
        <input type="text" value={alergias} onChange={(e) => setAlergias(e.target.value)} />

        <label>Profissão *</label>
        <input type="text" value={profissao} onChange={(e) => setProfissao(e.target.value)} />

        <label>CEP *</label>
        <input type="text" value={cep} onChange={(e) => setCep(e.target.value)} />

        <label>Endereço Completo *</label>
        <input type="text" value={enderecoCompleto} onChange={(e) => setEnderecoCompleto(e.target.value)} />

        <label>Problemas de Saúde</label>
        <input type="text" value={problemasSaude} onChange={(e) => setProblemasSaude(e.target.value)} />

        <label>Toma remédio para pressão ou outro medicamento contínuo?</label>
        <input type="text" value={remedios} onChange={(e) => setRemedios(e.target.value)} />

        <label>Já foi operado? Qual cirurgia?</label>
        <input type="text" value={cirurgias} onChange={(e) => setCirurgias(e.target.value)} />

        <label>É fumante? Quantos cigarros por dia?</label>
        <input type="text" value={fumante} onChange={(e) => setFumante(e.target.value)} />

        <label>Usou ou usa drogas entorpecentes? Essa informação precisa ser respondida com sinceridade, pois eles interferem na anestesia.</label>
        <input type="text" value={drogas} onChange={(e) => setDrogas(e.target.value)} />

        <label>Como conheceu a clínica mansur ?</label>
        <select value={comoConheceu} onChange={(e) => setComoConheceu(e.target.value)}>
          <option value="">Selecione uma opção</option>
          <option value="amigo">Indicação de Amigo</option>
          <option value="internet">Internet</option>
          <option value="outdoor">Outdoor</option>
          <option value="radio">Rádio</option>
          <option value="outros">Outros</option>
        </select>

        <label>
          <input
            type="checkbox"
            checked={concordo}
            onChange={(e) => setConcordo(e.target.checked)}
          />
          <br />
          Ao preencher esse formulário, você concorda com o armazenamento e tratamento desses dados por parte do corpo clínico, visando a realização de avaliação para cirurgia. Ressaltamos que a empresa está adequada à Lei Geral de Proteção de Dados e que esse tratamento é imprescindível à continuidade do processo para a realização do transplante capilar.
          <br />
          Concordo com os termos acima *
        </label>
        
        {mensagem && <div className="mensagem">{mensagem}</div>}
        {mensagem && (<div className="mensagem-sucesso">{mensagem}</div>
      )}

        <button onClick={salvarFormulario} disabled={!concordo}>Enviar Resposta</button>

        

      </div>
    </div>
  );
}

export default FormularioPreOperatorio;
